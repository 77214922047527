// on ajoute ce service au conteneur de service
globalThis.conteneurService.add("liste", () => new ListeService());

/**
 * Class ListeService
 * 
 * majTri(handleListe, nomColonne, action);
 * changerDePage(handleListe, numPage);
 * rechargerListe(handleListe);
 * exportPdf(handleListe);
 * exportExcel(handleListe);
 * addGeneriqueEvenementsListeAjax(handleListe, config);
 */
class ListeService {

	private conteneurListes: Array<any>

	/**
	 * Constructeur ListeService
	 */
	public constructor() {
		// contient les différentes liste avec leur fonction, majTri, changerDePage, recharger
		this.conteneurListes = [];
	}
		
	/**
	 * majTri depuis le service
	 */
	public majTri(handleListe: string, nomColonne: string, action: any): void {
		if ( this.conteneurListes[ handleListe ] != undefined ) {
			this.conteneurListes[ handleListe ].majTri(nomColonne, action);
		}
	}
	
	/**
	 * changerDePage depuis le service
	 */
	public changerDePage(handleListe: string, numPage: number|string): void {
		if ( this.conteneurListes[ handleListe ] != undefined ) {
			this.conteneurListes[ handleListe ].changerDePage(numPage);
		}
	}

	/**
	 * rechargerListe depuis le service
	 */
	public rechargerListe(handleListe: string): void {
		if (this. conteneurListes[ handleListe ] != undefined ) {
			this.conteneurListes[ handleListe ].rechargerListe();
		}
	}

	/**
	 * exportPdf depuis le service
	 */
	public exportPdf(handleListe: string): void {
		if (this. conteneurListes[ handleListe ] != undefined ) {
			this.conteneurListes[ handleListe ].exportPdf();
		}
	}

	/**
	 * exportExcel depuis le service
	 */
	public exportExcel(handleListe: string): void {
		if (this. conteneurListes[ handleListe ] != undefined ) {
			this.conteneurListes[ handleListe ].exportExcel();
		}
	}
	
	/**
	 * addGeneriqueEvenementsListeAjax
	 */
	public addGeneriqueEvenementsListeAjax(handleListe: string, config: Config): void {
		// si cet handle a déjà été défini, on l'écrase
		if ( this.conteneurListes[ handleListe ] != undefined ) {
			delete this.conteneurListes[ handleListe ];
		}
		
		// on prépare la liste
		this.conteneurListes[ handleListe ] = new Liste( handleListe, config );
	}

}

interface Config {
	id_html_form: string;
	id_html_liste: string;
	url_export_pdf?: string;
	url_export_excel?: string;
	isPagination?: boolean;
	isTri?: boolean;
	errorCallback?: any;
}

/** ###############################################################
 * Class Liste
 * 
 * majTri(nomColonne, action);
 * changerDePage(numPage);
 * rechargerListe();
 * exportPdf();
 * exportExcel();
 * 
 */
class Liste {

	protected handle_liste: string;
	protected id_html_form: string;
	protected id_html_liste: string;
	protected url_export_pdf: string|null;
	protected url_export_excel: string|null;
	protected isPagination: boolean;
	protected isTri: boolean;
	protected errorCallback: any;

	protected elForm: HTMLElement;
	protected elListe: HTMLElement;
	protected jqForm: JQuery;
	protected jqListe: JQuery;

	/**
	 * Constructeur
	 */
	public constructor( handleListe: string, config: Config ) {
		
		// le nom de son propre handle
		this.handle_liste = handleListe;
			
		/**
		 * @param string id_html_form, l'id de la balise form
		 */
		this.id_html_form = config.id_html_form;
		
		/**
		 * @param string id_html_liste, l'id de la balise contenant les résultats
		 */
		this.id_html_liste = config.id_html_liste;
		
		/**
		 * @param string url_export_pdf, l'url de l'action si on genere un pdf
		 * peut être null
		 */
		this.url_export_pdf = config.url_export_pdf ?? null;
		
		/**
		 * @param string url_export_excel, l'url de l'action si on genere un excel
		 * peut être null
		 */
		this.url_export_excel = config.url_export_excel ?? null;

		this.isPagination = config.isPagination ?? true;
		this.isTri = config.isTri ?? true;

		this.errorCallback = ( config.errorCallback !== undefined && typeof config.errorCallback === "function" ) ? config.errorCallback : null;

		this.elForm = document.getElementById( this.id_html_form ) as HTMLElement;
		this.elListe = document.getElementById( this.id_html_liste ) as HTMLElement;
		this.jqForm = jQuery( this.elForm );
		this.jqListe = jQuery( this.elListe );

		const lThis = this;
		
		// init du form
		globalThis.conteneurService.get("form").initForm( this.id_html_form );
		
		// ajout du listener sur la soumission du formulaire
		this.jqForm.on("submit", function(e) {
			
			// si export excel, on n'utilise pas ajax
			if ( jQuery(this).attr("target") == "_blank" ) return;
			
			e.stopPropagation();
			e.preventDefault();
			
			jQuery.ajax({
				"type": "POST",
				"url": jQuery(this).attr("action"),
				"data": globalThis.conteneurService.get("form").getFormData( lThis.id_html_form ),
				"cache": false,
				"processData": false,
				"contentType": false,
				"success": function(data) {
					lThis.jqListe.html(data);
				},
				"error": function(data) {
					if ( lThis.errorCallback !== null ) {
						lThis.errorCallback(data);
					} else {
						console.log("erreur ajax (addSubmitFiltreAjax)");
						lThis.jqListe.html("Erreur ajax");
					}
				}
			});
		});
		
		// ajout du listener sur les tris
		if (this.isTri) {
			this.jqListe.on("click", ".triable", function(e) {
				const lIdColonne = jQuery(this).attr("data-id-colonne") + "",
					action = jQuery(this).attr("data-action");
				
				lThis.majTri(lIdColonne, action);
			});
		}
		
		// ajout du listener sur les clicks sur la pagination
		if (this.isPagination) {
			this.jqListe.on("click", ".pagination > li > span", function(e) {
				const lActive = ( jQuery(this).attr("data-active") === "1" )? true : false,
					lNumPage = jQuery(this).attr("data-num-page") + "";
				
				if (lActive === false) lThis.changerDePage(lNumPage);
			});
		}
	}
	
	/**
	 * majTri
	 */
	public majTri(nomColonne: string, action: any): void {
		if ( !this.isTri ) return;

		const elTri = document.getElementById( this.handle_liste + "_tri" ) as HTMLElement,
			jqTri = jQuery( elTri ),
			elTriSens = document.getElementById( this.handle_liste + "_triSens" ) as HTMLElement,
			jqTriSens = jQuery( elTriSens );

		if (action == "remove") {
			jqTri.val("");
			jqTriSens.val("asc");
		} else {
			jqTri.val(nomColonne);
			jqTriSens.val(action);
		}
		this.rechargerListe();
	}
	
	/**
	 * changerDePage
	 */
	public changerDePage(numPage: number|string): void {
		if ( !this.isPagination ) return;

		const elPage = document.getElementById( this.handle_liste + "_page" ) as HTMLElement,
			jqPage = jQuery( elPage );

		jqPage.val(numPage);
		this.rechargerListe();
	}

	/**
	 * rechargerListe
	 */
	public rechargerListe(): void {
		this.jqForm.submit();
	}
	
	/**
	 * exportPdf
	 */
	public exportPdf(): void {
		if ( this.url_export_pdf === null ) return;

		const actionDefaut = this.jqForm.attr("action");
		this.jqForm.attr("action", this.url_export_pdf);
		this.jqForm.attr("target", "_blank");
		this.jqForm.submit();
		// on réaffecte l'action par défaut
		this.jqForm.attr("action", actionDefaut as string);
		this.jqForm.attr("target", "");
	}

	/**
	 * exportExcel
	 */
	public exportExcel(): void {
		if ( this.url_export_excel === null ) return;

		const actionDefaut = this.jqForm.attr("action");
		this.jqForm.attr("action", this.url_export_excel);
		this.jqForm.attr("target", "_blank");
		this.jqForm.submit();
		// on réaffecte l'action par défaut
		this.jqForm.attr("action", actionDefaut as string);
		this.jqForm.attr("target", "");
	}

}