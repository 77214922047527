// on ajoute ce service au conteneur de service
globalThis.conteneurService.add("ajax", () => new AjaxService());

/**
 * class AjaxService
 * 
 * addGeneriqueSubmit(lConfig);
 * addGeneriqueClickFenetre(lConfig);
 */
class AjaxService {

	/**
	 * Constructeur
	 */
	public Constructeur() {
	}
	
	/**
	 * addGeneriqueSubmit
	 * @param object = {
	 * 		"id_html_form": string
	 * 		"evenements": [
	 * 				{
	 * 					"code_ajax": string
	 * 					"action": function(data) {}
	 * 				}
	 * 		]
	 * 		"loader": function() {}
	 * 		"error": function(strMessage) {}
	 * }
	 */
	public addGeneriqueSubmit(lConfig) {
		const elForm = document.getElementById( lConfig.id_html_form ) as HTMLFormElement;
		const jqForm = jQuery( elForm );
		
		// init du form
		globalThis.conteneurService.get("form").initForm( lConfig.id_html_form );
		
		jqForm.on("submit", function(e) {
			e.stopPropagation();
			e.preventDefault();
			
			// on désactive la double validation
			if ( jQuery(this).attr("disabled") == "disabled" ) return;
			jQuery(this).attr("disabled", "disabled");
			
			if ( lConfig.loader !== undefined && typeof lConfig.loader === "function" ) lConfig.loader();
			
			const lien = jQuery(this).attr("action") as string;
			
			jQuery.ajax({
				type: "POST",
				url: lien,
				data: globalThis.conteneurService.get("form").getFormData( lConfig.id_html_form ),
				cache: false,
				processData: false,
				contentType: false,
				success: function(data, status, jqXhr) {
					
					var codeAjax = ( jqXhr.getResponseHeader("code-ajax") !== undefined && jqXhr.getResponseHeader("code-ajax") !== null )? jqXhr.getResponseHeader("code-ajax") : "defaut" ;
					
					lConfig.evenements.map(function(lEvenement) {
						if ( lEvenement.code_ajax === codeAjax ) {
							lEvenement.action( data );
							return;
						}
					});
				},
				error: function(message, status, stack) {
					console.error("Erreur ajax");
					if ( lConfig.error !== undefined && typeof lConfig.error === "function" ) lConfig.error("Erreur ajax");
				},
				complete: function() { 
					jqForm.removeAttr("disabled");
				}
			});
			
		});
	}
	
	/**
	 * addGeneriqueClickFenetre
	 * @param lConfig = {
	 * 		"selecteur_btn": string
	 * 		"handle_fenetre": string
	 * 		"lien": string
	 * 		"isExecute": function() {}
	 * }
	 */
	public addGeneriqueClickFenetre(lConfig) {
		jQuery( lConfig.selecteur_btn ).on("click", function(e) {
			e.stopPropagation();
			e.preventDefault();
			
			// si l'exécution de cette fonction est annulé
			if ( lConfig.isExecute !== undefined && lConfig.isExecute !== null && typeof lConfig.isExecute === "function" && lConfig.isExecute() == false ) return;
			
			let lLien: string;

			if ( lConfig.lien === undefined ) {
				// pas de lien renseigné on récupère par défaut
				lLien = jQuery(this).attr("href") as string;
				
			} else if ( typeof lConfig.lien === "function" ) {
				// si c'est une fonction
				lLien = lConfig.lien();
				
			} else {
				// si c'est un string
				lLien = lConfig.lien;
			}
			
			globalThis.conteneurService.get("fenetre").ouvrir( lConfig.handle_fenetre );
			
			jQuery.ajax({
				type: "GET",
				url: lLien,
//				data: null,
				cache: false,
				processData: false,
				contentType: false,
				success: function(data, status, jqXhr) {
					globalThis.conteneurService.get("fenetre").setContenu( lConfig.handle_fenetre, data);
				},
				error: function(message, status, stack) {
					console.log("erreur ajax (addGeneriqueClickFenetre)");
					globalThis.conteneurService.get("fenetre").setContenu( lConfig.handle_fenetre, "erreur ajax");
				}
			});
			
		});
	}

}