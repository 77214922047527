/**
 * class ConteneurService
 * 
 * get(lNomService);
 * add(lNomService, lFactory);
 * dump();
 */
class ConteneurService {

	private conteneur: Object;

	/**
	 * Constructeur
	 */
	public constructor() {
		this.conteneur = {};
	}

	/**
	 * getter de service
	 */
	public get(handleService: string): any|null {
		const service = this.conteneur[handleService];
		if ( service === undefined ) return null; 
		if ( service.instance === null ) service.instance = service.factory();
		return service.instance;
	}

	/**
	 * add service
	 */
	public add(handleService: string, factory: any): boolean {
		// ce service est déjà défini
		if ( this.conteneur[handleService] ) return false;

		// on ajoute le service au conteneur
		this.conteneur[handleService] = {
			"nom": handleService,
			"factory": factory,
			"instance": null
		};
		return true;
	}
	
	/**
	 * affiche tous les services
	 */
	public dump(): void {
		for (const handleService of Object.keys( this.conteneur )) {
			console.log(handleService);
		};
	}

}

// initialisation du ConteneurService
// on rend le conteneurService accéssible global
globalThis.conteneurService = new ConteneurService();