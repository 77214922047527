// on ajoute ce service au conteneur de service
globalThis.conteneurService.add("form", () => new FormService());

/**
 * Class FormService
 * 
 * initForm(lHandleFormulaire);
 * getFormData(lHandleFormulaire);
 * addVariablesFromAFormData(pForm, pData);   @deprecated
 */
class FormService {

	private m_conteneurFormulaires: Array<any>;

	/**
	 * Constructeur
	 */
	public constructor() {
		// contient les différents formulaires avec leur fonctions
		this.m_conteneurFormulaires = [];
	}
	
	/**
	 * initForm
	 * gère en autre les champs de type file
	 * @param lHandleFormulaire correspond a l'id du form
	 */
	public initForm(lHandleFormulaire: string): void {
		// si cet handle a déjà été défini, on l'écrase
		if ( this.m_conteneurFormulaires[ lHandleFormulaire ] != undefined ) {
			delete this.m_conteneurFormulaires[ lHandleFormulaire ];
		}
		
		// on ajoute ce formulaire initialisé au conteneur
		this.m_conteneurFormulaires[ lHandleFormulaire ] = new Formulaire( lHandleFormulaire );
	}
	
	/**
	 * getFormData
	 */
	public getFormData(lHandleFormulaire: string): FormData {
		if ( this.m_conteneurFormulaires[ lHandleFormulaire ] != undefined ) {
			return this.m_conteneurFormulaires[ lHandleFormulaire ].getFormData();
		} else {
			// si le formulaire n'a jamais été initialisé, on le fait maintenant
			this.initForm( lHandleFormulaire );
			return this.m_conteneurFormulaires[ lHandleFormulaire ].getFormData();
		}
	}
	
	
	/**
	 * addVariablesFromAFormData
	 * @param element jQuery Form pForm
	 * @param FormData pData
	 * @deprecated
	 */
	public addVariablesFromAFormData(pForm: JQuery, pData: FormData): void {
		pData = this.getFormData( pForm.attr("id") as string );
	}

}

/** ###############################################################
 * 
 * Class Formulaire
 * 
 */
class Formulaire {
	
	private m_handle_formulaire: string;
	private m_elForm: HTMLElement;
	private m_files: Array<any>;

	/**
	 * Constructeur Formulaire
	 * @param lHandleFormulaire correspond a l'id du form
	 */
	public constructor( lHandleFormulaire: string ) {
		// le nom de son propre handle
		this.m_handle_formulaire = lHandleFormulaire;
		this.m_elForm = document.getElementById(lHandleFormulaire) as HTMLElement;
		this.m_files = [];
		
		// on gère les champs files si il y en a
		const lThis = this;
		const listeFile = this.m_elForm.querySelectorAll("input[type=\"file\"]");
		[].forEach.call(listeFile, function(el: HTMLInputElement) {
			el.onchange = function(e: Event) { // prepareUpload
				lThis.m_files[ el.id ] = (<HTMLInputElement>e.target).files;
			};
		});
	}
	
	/**
	 * getFormData
	 */
	public getFormData(): FormData {
		const formData = new FormData();
		const lThis = this;
		
		// input
		const listeInput = this.m_elForm.querySelectorAll("input");
		[].forEach.call(listeInput, function(el: HTMLInputElement) {
			if ( el.type === "checkbox" || el.type === "radio" ) {
				if ( el.checked == true ) {
					formData.append( el.name, el.value );
				}
				
			} else if ( el.type === "file" ) {
				if ( undefined !== lThis.m_files[ el.id ] ) {
					// le fichier a été défini
					[].forEach.call(lThis.m_files[ el.id ], function(value) {
						formData.append( el.name, value );
					});
				}
				
			} else { // "text", "hidden", "password", ...
				formData.append( el.name, el.value );
				
			}
		});
		
		// textarea
		const listeTextArea = this.m_elForm.querySelectorAll("textarea");
		[].forEach.call(listeTextArea, function(el: HTMLTextAreaElement) {
			formData.append( el.name, el.value );
		});

		// select
		const listeSelect = this.m_elForm.querySelectorAll("select");
		[].forEach.call(listeSelect, function(elSelect: HTMLSelectElement) {
			const listeOption = elSelect.querySelectorAll("option:checked");
			[].forEach.call(listeOption, function(elOption: HTMLOptionElement) {
				formData.append( elSelect.name, elOption.value );
			});
		});
		
		return formData;
	}

}